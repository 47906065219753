<template>
  <b-button variant="secondary" size="lg" block class="mx-2" :disabled="disabled_btn" v-if="can_download" v-on:click.prevent="handleClick">
    <font-awesome-icon :icon="['fal', 'file-download']"/>
    Herunterladen (Download)
  </b-button>
</template>

<script>
import {DownloadService} from "@/service/download.service"

export default {
  name: "DownloadButton",
  data: function () {
    return {
      can_download: false,
      file_uri: '',
      expires: 0,
      timer: 60000,
      timer_active: false,
      disabled_btn: false
    }
  },
  methods: {
    async checkDownloads() {
      await DownloadService.getDownloads()
          .then((response) => {
            if (response.can_download) {
              this.can_download = response.can_download
              this.file_uri = response.file_uri
              this.timer = response.expires * 1000
            }

            if (!this.timer_active && this.can_download) {
              this.startTimer()
            }
          })
    },
    handleClick() {
      window.location.href = this.file_uri
    },
    startTimer() {
      this.timer_active = true
      setInterval(() => {
        this.checkDownloads()
      }, this.timer)
    }
  },
  mounted() {
    this.checkDownloads()
  }
}
</script>

<style scoped>

</style>