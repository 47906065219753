<template>
  <div id="usercontrols" class="user-controls">
    <div class="row">
      <!-- Gallery Button -->
      <div class="col-6 col-md-3 py-2" v-if="false">
        <b-button block v-b-toggle.collapse-1 variant="primary" size="lg" class="mx-2" v-if="is_started && field_streaming_gallery && false">
          <font-awesome-icon :icon="['fal', 'camera']"/>
          Foto upload...
        </b-button>
      </div>
      <!-- Chat Button -->
      <div class="col-6 col-md-3 py-2" v-if="false">
        <b-button block v-b-toggle.collapse-2 variant="primary" size="lg" class="mx-2" v-if="is_started && getChatEnabled && false">
          <font-awesome-icon :icon="['fal', 'comment-alt-edit']"/>
          Frage stellen
        </b-button>
      </div>
      <!-- Zurück Button -->
      <div class="col-6 col-md-3 py-2" v-if="false">
        <b-button block variant="light" size="lg" class="mx-2" v-if="is_started && getChatEnabled" @click="scroll">
          <font-awesome-icon :icon="['fal', 'long-arrow-up']"/>
          Zurück
        </b-button>
      </div>
      <!-- Download Button -->
      <div class="col py-2">
        <DownloadButton/>
      </div>
    </div>

    <!-- Gallery Upload -->
    <b-collapse id="collapse-1" class="mt-2" accordion="usercontrols-group" v-if="is_started && field_streaming_gallery && false">
      <div class="row py-md-5">
        <div class="col-md-8">
          <p>Hier können Bilder direkt zum Upload hinzugefügt werden. </p>
          <FileUpload :streaming_key="uuid"></FileUpload>
        </div>
        <div class="col-md-4 text-center py-5">
          <vue-qrcode :value="qrCodeUrl" errorCorrectionLevel="L"></vue-qrcode>
          <br/>
          <small class="px-2">Um Bilder mit dem Smartphone zu senden, einfach QR-Code scannen.</small>
        </div>
      </div>
    </b-collapse>

    <!-- Chat Fenster -->
    <b-collapse id="collapse-2" class="mt-2" accordion="usercontrols-group" v-if="is_started && getChatEnabled && false">
      <div class="row py-md-5">
        <div class="chat-container col">
          <Chat/>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode"
import Chat from "@/components/Chat"
import {mapGetters, mapState} from "vuex";
import FileUpload from "@/components/FileUpload";
import DownloadButton from "@/components/DownloadButton";

export default {
  name: "UserControls",
  components: {DownloadButton, FileUpload, VueQrcode, Chat},
  computed: {
    ...mapGetters("event", ["getChatEnabled"]),
    ...mapState("event", ["is_started", "field_streaming_gallery", "uuid"]),
    qrCodeUrl() {
      return "https://app.digitalstream.online/upload/" + this.uuid
    }
  },
  methods: {
    scroll() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }
}
</script>

<style scoped>

</style>