<template>
  <div class="plyr position-relative h-100 w-100">
    <video ref="videoPlayer" class="video-js vjs-16-9 vjs-big-play-centered h-100 w-100" :poster="playerPoster"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
//import 'videojs-contrib-quality-levels'
//import 'videojs-hls-quality-selector'
import qualityLevels from 'videojs-contrib-quality-levels';
import hlsQualitySelector from 'videojs-hls-quality-selector';

if (!videojs.getPlugin('qualityLevels')) videojs.registerPlugin('qualityLevels', qualityLevels)
if (!videojs.getPlugin('hlsQualitySelector')) videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)

import canAutoplay from "can-autoplay"

export default {
  name: 'VideoPlayer',
  props: {
    crossOrigin: {
      type: String,
      default: ''
    },
    playsinline: {
      type: Boolean,
      default: true
    },
    playerPoster: {
      type: String
    },
    playerAutoplay: {
      type: Boolean,
      default: true
    },
    playerSeekbar: {
      type: Boolean,
      default: true
    },
    playerSource: {
      type: [Object, Boolean],
      default: false
    },
    videojsOptions: {
      type: Object,
      default: function () {
        return {
          controlBar: {
            volumePanel: {
              inline: false
            },
            //progressControl: {},
          },
          html5: {
            vhs: {
              withCredentials: true,
              overrideNative: true,
              //enableLowInitialPlaylist: true,
              limitRenditionByPlayerDimensions: true
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false
          },
          controls: true,
          errorDisplay: false,
          sources: [],
        }
      }
    }
  },
  data() {
    return {
      player: null,
    }
  },
  watch: {
    playerSeekbar: function (value) {
      value ? this.player.controlBar.progressControl.enable() : this.player.controlBar.progressControl.disable()
    },
    playerSource: function (value) {
      this.initPlaylist(value)
    }
  },
  methods: {
    initPlayer: function () {
      // ios fullscreen
      if (this.playsinline) {
        this.$refs.videoPlayer.setAttribute('playsinline', this.playsinline)
        this.$refs.videoPlayer.setAttribute('webkit-playsinline', this.playsinline)
        this.$refs.videoPlayer.setAttribute('x5-playsinline', this.playsinline)
        this.$refs.videoPlayer.setAttribute('x5-video-player-type', 'h5')
        this.$refs.videoPlayer.setAttribute('x5-video-player-fullscreen', false)
      }

      // Wenn Autoplay enabled ist, dann prüfen ob Autoplay überhaupt möglich ist,
      // wenn ja: dann autoplay = true und muted = false
      // wenn nicht: dann autoplay = true und muted = true
      let playerMuted = false;
      if (this.playerAutoplay) {
        this.$refs.videoPlayer.setAttribute('autoplay', 'autoplay')

        canAutoplay.video().then(({result}) => {
          if (result !== true) {
            playerMuted = true
            this.$refs.videoPlayer.setAttribute('muted', 'muted')
          }
        })
      }

      this.player = videojs(this.$refs.videoPlayer, this.videojsOptions)
      this.player.qualityLevels();

      if (playerMuted) {
        this.player.muted(true)
      }

      // Player Seekbar
      this.playerSeekbar ? this.player.controlBar.progressControl.enable() : this.player.controlBar.progressControl.disable()
    },
    initPlaylist: function (src) {
      this.player.src(src)
    },
    initEventhandling: function () {

      //
      // Use VueJS Event Bus for VideoControl Elements
      //
      this.$root.$on('handleVideoplayerMuteControl', () => {
        const vol = this.player.volume();
        const lastVolume = this.player.lastVolume_();

        if (vol === 0) {
          const volumeToSet = lastVolume < 0.1 ? 0.1 : lastVolume;
          this.player.volume(volumeToSet);
          this.player.muted(false);
        }
        else {
          this.player.muted(!this.player.muted());
        }
      })

      //
      // Videoplayer Mute/Unmute > VueX
      //
      this.player.on(['loadstart', 'volumechange'], () => {
        if (this.player.volume() === 0 || this.player.muted()) {
          this.$store.dispatch("videoplayer/mute")
        }
        else {
          this.$store.dispatch("videoplayer/unmute")
        }
      })

      // At this point we have a src...
      // Load player every x seconds new if src file is not ready
      this.player.tech().on('retryplaylist', (e) => {
        console.log('RetryPlayList: ' + e)
        console.log(e)
      })

      this.player.tech().on('usage', (e) => {
        console.log('Usage: ' + e)
        console.log(e)
      })

      this.player.on('resolutionchange', (e) => {
        console.log(e)
      })

      // Wird getriggert wenn der Player online kommt, aber der Stream noch nicht bereit ist.
      // Dann reload (alle 5 sek)
      this.player.on('error', () => {
        if (this.player.error().code === 4) {
          this.player.retryLock = setTimeout(() => {
            this.$router.go()
          }, 5000);
        }
      })
    },
  },
  mounted() {
    this.initPlayer()
    this.initPlaylist(this.playerSource)
    this.initEventhandling()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped>

</style>
